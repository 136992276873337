import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { load } from 'recaptcha-v3';
import superagent from 'superagent';
import { ContactFormSectionInput, ContactFormSectionProps } from './ContactFormSection.model';
import emailValidationRegex from 'utils/emailValidationRegex';
import { Trans } from '@lingui/macro';
import Button from 'components/atoms/Button/Button';
import Image from 'components/atoms/Image/Image';

export const ContactFormSection: FC<ContactFormSectionProps> = () => {
  const emailEndPoint = 'https://europe-west3-yanchware.cloudfunctions.net/yanchwareContacts';

  const [submitSuccessState, setSubmitSuccessState] = useState(false);
  const [submitFailedState, setSubmitFailedState] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormSectionInput>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit: SubmitHandler<ContactFormSectionInput> = async (data) => {
    setSubmitButtonDisabled(true);

    try {
      const recaptcha = await load('6LeoaccZAAAAAH5_dOmimvOwM3rF62dh25UPKd6Z');
      const token = await recaptcha.execute('submit');
      const emailDeliveryRequest = { ...data, token };

      await superagent
        .post(emailEndPoint)
        .send(emailDeliveryRequest)
        .set('Content-Type', 'application/json');

      setSubmitSuccessState(true);
    } catch (error) {
      setSubmitFailedState(true);
    } finally {
      setSubmitButtonDisabled(false);
    }
  };

  if (submitSuccessState) {
    return (
      <div className="contact-section__form">
        <div className="contact-section__form-sent-successfully tac">
          <h3>
            <Trans>Thank you for reaching out!</Trans>
          </h3>
          <p>
            <Trans>You will get a response as soon as possible!</Trans>
          </p>
        </div>
      </div>
    );
  }

  return (
    <section className="w-full flex justify-center items-center gap-14 mt-64" id="contact-section">
      <div className="w-[25rem] h-[25rem] hidden xl:block">
        <Image name="home-page/contact-us" className="" />
      </div>
      <div className="flex justify-center">
        <form
          noValidate
          className="w-[23rem] md:w-[35rem] contact-section__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="contact-section__row">
            <label htmlFor="cf-name">
              <Trans>Name</Trans>
            </label>
            <input
              id="cf-name"
              type="text"
              placeholder="Enter name"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.name,
              })}
              {...register('name', { required: 'Name is required.' })}
              aria-invalid={errors.name ? 'true' : 'false'}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.name,
              })}
            >
              {errors.name?.message}
            </div>
          </div>
          <div className="contact-section__row">
            <label htmlFor="cf-company">
              <Trans>Company</Trans>
            </label>
            <input
              id="cf-company"
              type="text"
              placeholder="Enter company name"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.company,
              })}
              {...register('company', { required: 'Company is required.' })}
              aria-invalid={errors.company ? 'true' : 'false'}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.company,
              })}
            >
              {errors.company?.message}
            </div>
          </div>
          <div className="contact-section__row">
            <label htmlFor="cf-phone-number">
              <Trans>Phone Number</Trans>
            </label>
            <input
              id="cf-phone-number"
              type="tel"
              placeholder="Enter number"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.phoneNumber,
              })}
              {...register('phoneNumber', { required: 'Phone number is required.' })}
              aria-invalid={errors.phoneNumber ? 'true' : 'false'}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.phoneNumber,
              })}
            >
              {errors.phoneNumber?.message}
            </div>
          </div>
          <div className="contact-section__row">
            <label htmlFor="cf-company-email">
              <Trans>Company e-mail</Trans>
            </label>
            <input
              id="cf-company-email"
              type="email"
              placeholder="Enter e-mail"
              className={classNames('contact-section__input', {
                'contact-section__input--error': errors.companyEmail,
              })}
              {...register('companyEmail', {
                required: 'Company email is required.',
                pattern: {
                  value: emailValidationRegex,
                  message: 'Invalid email format.',
                },
              })}
              aria-invalid={errors.companyEmail ? 'true' : 'false'}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.companyEmail,
              })}
            >
              {errors.companyEmail?.message}
            </div>
          </div>
          <div className="contact-section__row">
            <label htmlFor="cf-message">
              <Trans>Message</Trans>
            </label>
            <textarea
              id="cf-message"
              placeholder="Enter message"
              rows={5}
              className={classNames('contact-section__textarea', {
                'contact-section__textarea--error': errors.message,
              })}
              {...register('message', { required: 'Message is required.' })}
              aria-invalid={errors.message ? 'true' : 'false'}
            />
            <div
              role="alert"
              className={classNames('contact-section__input-error', {
                'contact-section__input-error--show': errors.message,
              })}
            >
              {errors.message?.message}
            </div>
          </div>

          <div>
            <Button kind="Primary" type="submit" disabled={submitButtonDisabled} className="w-full">
              {submitButtonDisabled ? 'Sending...' : 'Send Message'}
            </Button>
          </div>

          {submitFailedState && (
            <div className="contact-section__form-error">
              <Trans>Oh no! Something went wrong! Please refresh the page and try again.</Trans>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};
