import React, { FC } from 'react';
import { CardInfoImageSectionProps } from './CardInfoImageSection.model';
import { CardIconProps } from 'components/atoms/CardIcon/CardIcon.model';
import { CardIcon } from 'components/atoms/CardIcon/CardIcon';
import Image from 'components/atoms/Image/Image';

export const CardInfoImageSection: FC<CardInfoImageSectionProps> = ({
  title,
  cardIcons,
  className = 'flex-col lg:flex-row',
  imageName,
}) => {
  return (
    <section className="flex justify-center mt-40 m-8">
      <div
        className={`flex ${className} justify-between gap-10 items-center p-10 max-w-[75rem] border border-base-200 rounded-lg`}
      >
        <div className="flex flex-col">
          <h2 className="hidden lg:block text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            {title}
          </h2>
          {cardIcons?.map((cardIcon: CardIconProps) => (
            <CardIcon {...cardIcon} className="flex" />
          ))}
        </div>

        <div className="w-auto md:w-[45rem]">
          <h2 className="block lg:hidden text-center text-[24px] font-[500] text-transparent bg-clip-text bg-hero-text">
            {title}
          </h2>
          <Image name={imageName} className="" />
        </div>
      </div>
    </section>
  );
};
