import { Trans } from '@lingui/macro';
import Button from 'components/atoms/Button/Button';
import { CardIconProps } from 'components/atoms/CardIcon/CardIcon.model';
import { IconFA } from 'components/atoms/IconFA/IconFA';
import Image from 'components/atoms/Image/Image';
import { CardInfoImageSection } from 'components/CardInfoImageSection/CardInfoImageSection';
import FractalJoin from 'components/Carousel/FractalJoin/FractalJoin';
import { CompanyWorkWithUsSection } from 'components/CompanyWorkWithUsSection/CompanyWorkWithUsSection';
import { CompareSolutionSection } from 'components/CompareSolutionSection/CompareSolutionSection';
import { ContactFormSection } from 'components/ContactFormSection/ContactFormSection';
import { ElevateDevelopmentGameSection } from 'components/ElevateDevelopmentGameSection/ElevateDevelopmentGameSection';
import { GreatPrideSection } from 'components/GreatPrideSection/GreatPrideSection';
import Layout from 'components/Layout';
import { OurHistoryVision } from 'components/OurHistoryVision/OurHistoryVision';
import Seo from 'components/SEO';
import { SustainableGoalsSection } from 'components/SustainableGoalsSection/SustainableGoalsSection';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import PricingSection from 'src/page-components/pricing/PricingSection';

type openFractalJoin = 'crowd' | 'enterprise';

const FractalCloudVsTerraform = () => {
  const [isFractalJoinOpen, setIsFractalJoinOpen] = useState(false);
  const [fractalJoinPage, setFractalJoinPage] = useState<openFractalJoin>('crowd');

  const cardIconsLiveSystem: CardIconProps[] = [
    {
      title: 'Faster Deployments',
      subTitle:
        'Deploy infrastructure with minimal code, accelerating time-to-market and iterations.',
      iconName: 'live-system-first-section/faster-deployments',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Built-In Security',
      subTitle:
        'Pre-configured, security-hardened components ensure secure environments from the start.',
      iconName: 'live-system-first-section/built-in-security',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Reusable Blueprints',
      subTitle:
        'Extract and share Blueprints across teams, making scaling and collaboration seamless.',
      iconName: 'live-system-first-section/reusable-blueprints',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const cardIconsFractal: CardIconProps[] = [
    {
      title: 'Simplifies Cloud Complexity',
      subTitle: 'Use reusable Blueprints to manage Cloud-Native complexity effortlessly.',
      iconName: 'fractal-first-section/simplifies-cloud-complexity',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Compliance by Design',
      subTitle: 'Versioned Blueprints maintain compliance automatically as systems evolve.',
      iconName: 'fractal-first-section/compliance-by-design',
      iconClassName: 'w-14 md:w-10',
    },
    {
      title: 'Effortless Multi-Cloud',
      subTitle:
        'Instantly deploy Blueprints across any cloud vendor with minimal configuration needed.',
      iconName: 'fractal-first-section/effortless-multi-cloud',
      iconClassName: 'w-14 md:w-10',
    },
  ];

  const openFractalJoin = (page: openFractalJoin) => {
    setIsFractalJoinOpen(page !== fractalJoinPage || !isFractalJoinOpen);
    setFractalJoinPage(page);

    const section = document.getElementById('fractal_join');
    if (section !== null) {
      section.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout className="fractal-page" contactForm={false}>
      <Seo title="Fractal Cloud vs Terraform" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="hero-wrapper">
        <div className="flex flex-col justify-center items-center pt-40 m-auto h-[35rem] md:h-auto">
          <div className="text-white text-center px-9 mb-6 md:mb-4">
            <h1 className="text-4xl mb-6 md:mb-4">
              <Trans>Fractal Cloud</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                The best Internal Developer Platform that supports the entire software development
                lifecycle.
              </Trans>
            </p>
          </div>

          <div className="relative w-full hidden md:flex justify-center h-[29rem]">
            <Image name="fractal-vs-terraform/hero-image" className="max-w-[100rem]" />
            <div className="absolute inset-0 bg-gradient-to-t from-[#1e1374] to-transparent h-full" />
            <div className="absolute bottom-20">
              <a href="#pricing_cards">
                <Button kind="Primary" className="w-full text-nowrap">
                  <div className="flex items-center gap-3">
                    <span>Get Started</span>
                    <IconFA icon="arrow-right" className="w-4" />
                  </div>
                </Button>
              </a>
            </div>
          </div>

          <div className="block md:hidden">
            <a href="#pricing_cards">
              <Button kind="Primary" className="w-full text-nowrap">
                <div className="flex items-center gap-3">
                  <span>Get Started</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>

      <ElevateDevelopmentGameSection />
      <CompareSolutionSection
        title="Features Battle"
        subtitle="Which platform offers the best features for efficient software development? You make the choice."
        isPulumiVisible={false}
        isCompareNameVisible={false}
      />
      <CompanyWorkWithUsSection title="Companies that trusted us" />

      <PricingSection openForm={openFractalJoin} />
      <section id="fractal_join" className="fractal-join">
        <FractalJoin page={fractalJoinPage} isVisible={isFractalJoinOpen} />
      </section>

      <ContactFormSection />

      <CardInfoImageSection
        title="Live-System First"
        cardIcons={cardIconsLiveSystem}
        className="flex flex-col-reverse lg:flex-row"
        imageName="live-system-first-section/live-system-image"
      />

      <CardInfoImageSection
        title="Fractal First"
        cardIcons={cardIconsFractal}
        className="flex flex-col-reverse lg:flex-row-reverse"
        imageName="fractal-first-section/fractal-first-image"
      />

      <section className="flex flex-col items-center justify-center max-w-[70rem] h-fit m-auto mt-64">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-8">
          <Trans>Our Certifications</Trans>
        </h2>

        <div className="w-full flex flex-col md:flex-row gap-6 items-center justify-between">
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Microsoft" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/Google_Cloud" />
          </div>
          <div className="rounded-lg border border-base-200 py-6 px-8 w-fit">
            <Image name="our-certification-section/AWS" />
          </div>
        </div>
      </section>

      <GreatPrideSection />
      <OurHistoryVision />
      <SustainableGoalsSection />

      <section className="flex flex-col justify-center items-center my-64 m-auto px-9">
        <div className="text-center">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-4">
            <Trans>Join the Cloud Revolution</Trans>
          </h2>
          <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[80rem] mb-6">
            Are you ready to embark on a transformative journey?
          </p>
          <p className="text-[16px] font-[400] leading-[175%] max-w-[50rem] mb-8">
            Let us be your guide to success—where passion meets technology, and possibilities become
            realities. Together, we'll unleash the true power of cloud computing, fuel innovation,
            and empower individuals and organizations worldwide.
          </p>
          <div className="flex justify-center">
            <a href="#contact-section">
              <Button kind="Primary" className=" text-nowrap py-">
                <div className="flex items-center gap-3">
                  <span>Get in touch</span>
                  <IconFA icon="arrow-right" className="w-4" />
                </div>
              </Button>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default FractalCloudVsTerraform;
